@import "melange-css/melange-thin.css";
@import "./shadow-button.css";
@import "./g-element-source.css";
@import "./palette.css";
@import "./g-test-case.css";
@import "./preview.css";

:root {
  --mg-ff-sans: "Helvetica Neue", Inter, Roboto, "Nimbus Sans", sans-serif;
  --mg-ff-serif: "Baskerville", Charter, "Bitstream Charter", "Sitka Text", Cambria, serif;
  --mg-ff-mono: Courier, monospace;
}

:root {


  --mg-red-100: #180A09;
  --mg-red-200: #28110F;
  --mg-red-300: #51211D;
  --mg-red-400: #883832;
  --mg-red-500: #b84c43;
  --mg-red-600: #D6948F;
  --mg-red-700: #EBCBC8;
  --mg-red-800: #F9EFEE;
  --mg-red-900: #FEFBFB;

  --mg-green-100: #001F0F;
  --mg-green-200: #00341A;
  --mg-green-300: #006733;
  --mg-green-400: #00AE56;
  --mg-green-500: #00eb74;
  --mg-green-600: #5DFFAD;
  --mg-green-700: #AFFFD7;
  --mg-green-800: #E6FFF2;
  --mg-green-900: #FAFFFC;
}

@media screen and (max-width: 80em) {
  /** On smaller than ideal, bump all font sizes down one */
  :root {
    --mg-fs-1: 0.765rem;
    --mg-fs-2: 0.875rem;
    --mg-fs-3: 1rem;
    --mg-fs-4: 1.25rem;
    --mg-fs-5: 1.5rem;
    --mg-fs-6: 2.25rem;
    --mg-fs-7: 3rem;
    --mg-fs-8: 4.25rem;
    --mg-fs-9: 5rem;
  }
}

/* tame the color input */
input[type="color"] {
  -webkit-appearance: none;
  border: none;
}
input[type="color"]::-webkit-color-swatch-wrapper {
  border: none;
  margin: 0;
  padding: 0;
  border-radius: 0;
}

input[type="color"]::-webkit-color-swatch,
input[type="color"]::-moz-color-swatch {
  border: none;
  border-top-width: 0;
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
input[type=color] {
  border-top-width: 0;
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  margin: 0;
  padding: 0;
}

/* Make sure <code> uses the theme's monospace font */
code {
  font-family: var(--mg-ff-mono);
}

/* Since some inputs will not be obviously inputs, 
 * make sure disabled ones are clearly disabled
 */
input:disabled.pointer {
  cursor: not-allowed;
}

.mb-half {
  margin-bottom: calc(var(--mg-sp-1) / 2);
}

.inner-shadow-1 {
  box-shadow: inset 0 0 3px var(--mg-gray-500);
}

.pointer-events-none {
  pointer-events: none;
}

.inset-shadow-1 {
  box-shadow: rgb(97, 97, 97) 1px 1px 3px 0px inset
}

.inset-shadow-2 {
  box-shadow: rgb(97, 97, 97) 1px 1px 4.65px 0px inset
}

.inset-shadow-3 {
  box-shadow: rgb(97, 97, 97) 1px 1px 8.115px 0px inset
}

.inset-shadow-4 {
  box-shadow: rgb(97, 97, 97) 1px 1px 15.3915px 0px inset
}

.inset-shadow-5 {
  box-shadow: rgb(97, 97, 97) 1px 1px 30.67215px 0px inset
}

.grid-4x4 {
  display: grid; 
  grid-template-columns: auto auto auto auto; 
  grid-template-rows:    auto auto auto auto; 
  grid-template-areas: '. . . .'
                       '. . . .'
                       '. . . .'
                       '. . . .'; 
}

g-xy-input [data-xy] {
  grid-area: xy-control; 
  width: calc(1.5 * var(--mg-sp-6));
  height: calc(1.5 * var(--mg-sp-6));
}

.active-bg-green-600:active {
  background-color: var(--mg-green-600);
}
.active-black-ish:active {
  color: var(--mg-black-ish);
}

g-box-shadow div {
  width: calc(1.5* var(--mg-sp-5));
  height: calc(1.5* var(--mg-sp-5));
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: var(--mg-fs-1);
  color: var(--mg-gray-300);
}

div:has(g-box-shadow):nth-child(1) {
  padding: var(--mg-sp-2);
}
div:has(g-box-shadow):nth-child(2) {
  padding: calc(1.5 * var(--mg-sp-2));
}

div:has(g-box-shadow):nth-child(3) {
  padding: var(--mg-sp-3);
}
div:has(g-box-shadow):nth-child(4) {
  padding: calc(1.5 * var(--mg-sp-3));
}

div:has(g-box-shadow):nth-child(5) {
  padding: var(--mg-sp-4);
}
div:has(g-box-shadow):nth-child(6) {
  padding: calc(1.5 * var(--mg-sp-4));
}

#css {
  width: 60%;
}
@media screen and (max-width: 36em) {
  #css {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
}

@media screen and (max-width: 36em) {
  g-copy-code button {
    float: none;
    margin-left: auto;
    margin-right: auto;
    margin-top: var(--mg-sp-2);
    margin-bottom: var(--mg-sp-4);
  }
}
