.match-shadow-transform-y {
  transform: translateY(-1px);
}

.shadow-button {
  transform: translateX(-1px) translateY(-1px);
  box-shadow: 1px 1px 2px 1px rgba( 80, 80, 80, 0.5 );
  line-height: var(--mg-lh-solid);
  padding-left: var(--mg-sp-2);
  padding-right: var(--mg-sp-2);
  padding-top: var(--mg-sp-1);
  padding-bottom: var(--mg-sp-1);
  border-style: solid;
  border-width: 1px;
  text-align: center;
  cursor: pointer;

  background-color: var(--mg-gray-900);
  color: var(--mg-gray-300);
  border-color: var(--mg-gray-600);
}

.shadow-button.f-2,
.shadow-button.f-3 {
  padding-top: var(--mg-sp-2);
  padding-bottom: var(--mg-sp-2);
}
@media screen and (max-width: 80em) {
  .shadow-button {
    padding-top: calc(0.5 * var(--mg-sp-1));
    padding-bottom: calc(0.5 * var(--mg-sp-1));
    font-size: var(--mg-fs-1);
  }
  .shadow-button.f-2,
  .shadow-button.f-3 {
    padding-top: var(--mg-sp-1);
    padding-bottom: var(--mg-sp-1);
    font-size: var(--mg-fs-1);
  }
}

.shadow-button-300 {
  background-color: var(--mg-gray-300);
  color: var(--mg-gray-800);
  border-color: var(--mg-gray-500);
  box-shadow: 1px 1px 2px 1px rgba( 20, 20, 20, 0.5 );
}

.shadow-button--red {
  background-color: var(--mg-red-900);
  color: var(--mg-red-300);
  border-color: var(--mg-red-700);
}

.shadow-button.pv-3 {
  padding-top: var(--mg-sp-3);
  padding-bottom: var(--mg-sp-3);
}

.shadow-button:active {
  box-shadow: none;
  transform: none;
}
.shadow-button:disabled {
  box-shadow: none;
  transform: none;
  background: var(--mg-gray-700);
  color: var(--mg-gray-900);
  border-color: transparent;
  cursor: not-allowed;
  font-style: italic;
}
.shadow-button:active:disabled {
  box-shadow: none;
}
