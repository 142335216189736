g-palette {
  display: flex;
  flex-direction: column;
  gap: var(--mg-sp-4);
}
g-palette[compact] {
  gap: var(--mg-sp-1);
}

g-palette-color-scale {
  display: flex;
  justify-content: center;
  gap: var(--mg-sp-3);
}

g-palette[compact] g-palette-color-scale {
  align-items: center;
}

g-palette[compact] g-palette-color-scale nav {
  display: none;
}

g-palette-color-scale g-color-swatch {
  border-width: 0;
  padding: var(--mg-sp-1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--mg-sp-1);
}


g-palette[compact] g-palette-color-scale g-color-swatch {
  border-width: 0;
  border-style: none;
  border-radius: 0;
  padding: 0;
  border-radius: 0;
}

g-palette-color-scale g-color-swatch:has(input) {
  box-shadow: 1px 1px 2px 1px rgba( 80, 80, 80, 0.5 );
  border-color: var(--mg-gray-500);
  border-radius: var(--mg-bw-2);
}

g-palette-color-scale g-color-swatch:has(input:disabled) {
  box-shadow: none;
  border-radius: var(--mg-bw-1);
}

g-palette-color-scale g-color-swatch input,
g-palette-color-scale g-color-swatch [data-color] {
  display: block;
  width: calc(1.5 * var(--mg-sp-5));
  height: calc(1.5 * var(--mg-sp-5));
}
g-color-swatch.small input,
g-color-swatch.small [data-color] {
  width: calc(var(--mg-sp-5));
  height: calc(var(--mg-sp-5));
}

@media screen and (max-width: 80em) {
  g-palette-color-scale g-color-swatch input,
  g-palette-color-scale g-color-swatch [data-color] {
    width: calc(2 * var(--mg-sp-4));
    height: calc(2 * var(--mg-sp-4));
  }
}

g-palette-color-scale g-color-swatch input {
  cursor: pointer;
}

g-palette-color-scale g-color-swatch input:disabled {
  cursor: not-allowed;
}

g-palette[compact] g-palette-color-scale g-color-swatch input,
g-palette[compact] g-palette-color-scale g-color-swatch [data-color] {
  height: calc(2 * var(--mg-sp-3));
}

g-palette-color-scale g-color-swatch label,
g-palette-color-scale g-color-swatch [data-hexcode] {
  display: block;
  text-align: center;
  font-weight: 300;
  font-size: var(--mg-fs-1);
}

@media screen and (max-width: 80em) {
  g-palette g-palette-color-scale g-color-swatch [data-link-context] {
    max-width: calc(2 * var(--mg-sp-4));
    width: 100%;
    white-space: nowrap;
    overflow-x: scroll;
  }
}

g-palette[compact] g-palette-color-scale g-color-swatch label,
g-palette[compact] g-palette-color-scale g-color-swatch [data-link-context],
g-palette[compact] g-palette-color-scale g-color-swatch [data-hexcode],
g-palette[compact] g-palette-color-scale g-color-swatch [data-secondary] {
  display: none;
}

g-color-name label:has(input[data-user-override]) [data-overridden] {
  visibility: visible;
}
g-color-name label:not(:has(input[data-user-override])) [data-overridden] {
  visibility: hidden;
}

g-palette-color-scale g-color-name input {
  font-size: var(--mg-fs-3);
  padding: var(--mg-sp-2);
}
g-palette[compact] g-palette-color-scale g-color-name input {
  font-size: var(--mg-fs-2);
  padding: var(--mg-sp-1);
}
g-palette g-palette-color-scale g-color-name label {
  margin-bottom: var(--mg-sp-3);
}
@media screen and (max-width: 80em) {
  g-palette-color-scale g-color-name input {
    font-size: var(--mg-fs-2);
    padding: var(--mg-sp-1);
  }
  g-palette[compact] g-palette-color-scale g-color-name input {
    font-size: var(--mg-fs-1);
    padding: calc(0.5 * var(--mg-sp-1));
  }
  g-palette g-palette-color-scale g-color-name label {
    margin-bottom: var(--mg-sp-2);
  }
}
g-palette[compact] g-palette-color-scale g-color-name label {
  margin-bottom: 0;
}
g-palette[compact] g-palette-color-scale g-color-name label span {
  display: none;
}
