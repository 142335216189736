g-element-source pre {
  display: inline-block;
  background-color: var(--mg-black-ish);
  color: var(--mg-green-800);
  padding-left: var(--mg-sp-3);
  padding-right: var(--mg-sp-3);
  padding-top: var(--mg-sp-2);
  padding-bottom: var(--mg-sp-2);
  line-height: var(--mg-lh-title);
  border-radius: var(--mg-bw-3);
  font-size: var(--mg-fs-1);
  text-shadow: rgba(230,255,130,0.61) 0px 0px 5px;
}
@media (prefers-contrast: more) {
  g-element-source pre {
    font-size: var(--mg-fs-1);
    color: var(--mg-green-900);
    text-shadow: none;
  }
}
